import styled from "styled-components";

import { themeHelpers } from "Libs/theme";

export const DropdownLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  .input-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .select-box {
    width: 264px;
  }
  .select-box,
  .select-box__control,
  .select-box__value-container {
    height: 32px;
  }
  .select-box__control {
    background: ${props =>
      props.theme.name === "dark"
        ? themeHelpers(props.theme, "darkShade")
        : props.theme.inputBg};
    border: 1px solid
      ${props =>
        props.theme.name === "dark"
          ? themeHelpers(props.theme, "darkShade")
          : props.theme.inputBg};
    border-radius: 2px;
    min-height: 32px;
    &.select-box__control--menu-is-open {
      .select-box__dropdown-indicator svg {
        transform: rotate(180deg);
      }
    }
  }
  .select-box__placeholder {
    font-size: 15px;
    color: ${props => props.theme.sectionText};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-right: 10px;
    max-width: 100%;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .select-box:hover {
    .select-box__control {
      background: ${props =>
        props.theme.name === "dark"
          ? themeHelpers(props.theme, "darkShade")
          : props.theme.inputBg};
      border: 1px solid
        ${props =>
          props.theme.name === "dark"
            ? themeHelpers(props.theme, "darkShade")
            : props.theme.inputBg};
    }
    .select-box__single-value,
    .select-box__placeholder,
    .select-box__dropdown-indicator svg {
      color: ${props => props.theme.links};
    }
  }
  .select-box__value-container {
    padding: 0 16px;
    .select-box__single-value {
      height: 32px;
      line-height: 30px;
    }
  }
  .select-box__indicator-separator {
    display: none;
  }
  .select-box__dropdown-indicator {
    padding: 0 6px 0 0;
    svg {
      color: ${props => props.theme.chevron};
    }
  }
  .select-box__control--is-focused {
    border: 1px solid ${props => themeHelpers(props.theme, "primaryShade")} !important;
    box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "primaryShade")};
    outline: none;
  }
  .select-box__control--is-disabled {
    cursor: not-allowed;
    border: none;
    .select-box__indicators {
      display: none;
    }
  }
  .select-box__single-value {
    font-size: 15px;
    margin: 0;
    color: ${props => props.theme.sectionText};
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .select-box__menu {
    border-radius: 2px;
    background: ${props => themeHelpers(props.theme, "dropdownColor")};
    border: none;
    box-shadow: ${props => props.theme.menuDropShadow};
    .select-box__menu-list {
      padding: 8px;
    }
    .select-box__option {
      line-height: 22px;
      min-height: 32px;
      display: flex;
      align-items: center;
      color: ${props => props.theme.sectionText};
      padding: 5px 8px;
      border-radius: 4px;
      font-size: 15px;
      &:first-letter {
        text-transform: uppercase;
      }
      &:hover {
        background: ${props => themeHelpers(props.theme, "linkHover")};
      }
      &:active {
        background: transparent;
        color: ${props => props.theme.links};
      }
    }
    .select-box__option--is-focused {
      background: ${props => themeHelpers(props.theme, "linkHover")};
    }
    .select-box__option--is-selected {
      background: transparent;
      color: ${props => props.theme.links};
    }
  }
  .required-message {
    display: inline-block;
    color: ${props => props.theme.fieldError};
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &.outlined {
    .select-box {
      width: 132px;
    }
    .select-box__control,
    .select-box:hover .select-box__control {
      cursor: hover;
      border: 1px solid ${props => props.theme.sectionBorder};
      border-radius: 4px;
      background: transparent;
      &:focus {
      }
    }
  }
  &.is-disabled {
    cursor: not-allowed;
    label {
      opacity: 0.5;
    }
  }
  &.field-error {
    label,
    .required-message {
      color: ${props => props.theme.fieldError};
    }
    .required-notice {
      background: ${props => props.theme.fieldError};
    }
    .select-box__control {
      border: 1px solid ${props => props.theme.fieldError};
      margin: -1px;
    }
  }
  &.inline {
    .input-wrapper {
      flex-direction: row;
    }
  }
  &.dropdown {
    background-color: transparent;
  }
`;

export const InputLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  width: 264px;
  position: relative;
  &.stacked {
    flex-direction: column;
    align-items: flex-start;
    .Select,
    .Select-control,
    .Select-menu-outer {
      width: 100% !important;
    }
    .Select {
      background: #f7f7f7;
    }
  }
  .Select-input:focus {
    background: transparent;
  }
`;
