import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import styled from "styled-components";

const ActivityTitleWrapper = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.sectionText};

  @media (min-width: 768px) {
    display: inline-block;
    /* The 42px is to leave space for the commits count */
    max-width: calc(100% - 42px);
  }

  &:focus {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
  }
`;

const CustomActivityText = styled.span``;

class ActivityTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: ""
    };
  }

  render() {
    const {
      name,
      primaryTitle,
      primaryTitleCustom,
      secondaryTitle,
      primaryText,
      secondaryText,
      description,
      intl,
      link = false
    } = this.props;

    return (
      <ActivityTitleWrapper>
        {description ? (
          <CustomActivityText
            className="custom-activity-message"
            dangerouslySetInnerHTML={{ __html: description }} // eslint-disable-line
          />
        ) : (
          <React.Fragment>
            <strong>{`${name} `}</strong>
            {`${intl.formatMessage({ id: primaryText || "No description" })} `}
            {(primaryTitle || primaryTitleCustom) &&
              (primaryTitle ? (
                <strong title={primaryTitle.length > 20 ? primaryTitle : ""}>
                  {primaryTitle}
                </strong>
              ) : (
                primaryTitleCustom
              ))}
            {secondaryText && (
              <React.Fragment>
                {` ${intl.formatMessage({
                  id: secondaryText || "No description"
                })} `}
                <strong>{secondaryTitle}</strong>
              </React.Fragment>
            )}
            {link}
          </React.Fragment>
        )}
      </ActivityTitleWrapper>
    );
  }
}

ActivityTitle.propTypes = {
  name: PropTypes.string,
  picture: PropTypes.string,
  primaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primaryTitleCustom: PropTypes.node,
  secondaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  description: PropTypes.string,
  intl: PropTypes.object,
  link: PropTypes.node,
  onClick: PropTypes.func
};

export default injectIntl(ActivityTitle);
