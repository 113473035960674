import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import LabeledInfo from "Components/LabeledInfo";

const ServiceInfos = ({ metadata }) => {
  const intl = useIntl();

  return (
    <>
      <LabeledInfo
        label={intl.formatMessage({ id: "version" })}
        value={
          metadata.type.split(":")[1] || intl.formatMessage({ id: "unknown" })
        }
      />
      {metadata.disk && (
        <LabeledInfo
          label={intl.formatMessage({ id: "disk" })}
          value={metadata.disk}
        />
      )}
      <LabeledInfo
        label={intl.formatMessage({ id: "size" })}
        value={metadata.size || intl.formatMessage({ id: "unknown" })}
      />
    </>
  );
};

ServiceInfos.propTypes = {
  metadata: PropTypes.object
};

export default ServiceInfos;
