import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { ThemeContext } from "styled-components";
import ReactTooltip from "react-tooltip";

import ListIcon from "Icons/ListIcon";
import GridIcon from "Icons/GridIcon";
import ToggleWrapper from "Components/ToggleWrapper";
import IconSortDirection from "Icons/IconSortDirection";
import Heading6 from "Components/styleguide/Heading6";
import RadioField from "Components/fields/RadioField";

import * as S from "./styles";

const SORT_TYPES = [
  { id: "name", ascend: "A-Z", descend: "Z-A" },
  { id: "date", ascend: "recent last", descend: "recent first" },
  { id: "owner", ascend: "A-Z", descend: "Z-A" },
  { id: "plan", ascend: "D-XXL", descend: "XXL-D" },
  { id: "region", ascend: "A-Z", descend: "Z-A" }
];

const ToggleLayout = ({
  toggleLayout,
  gridLayout,
  sortType,
  setSortType,
  sortOrder,
  setSortOrder
}) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const selected =
    SORT_TYPES.find(({ id }) => id === sortType) || SORT_TYPES[0];

  return (
    <ToggleWrapper>
      <S.ProjectsSort
        id="projects-sort"
        label={`${intl.formatMessage({
          id: `projects.toggle.sort_by.${selected.id}`,
          defaultMessage: `Sort by ${selected.id}`
        })}`}
        ariaLabel={`${intl.formatMessage({
          id: `projects.toggle.sort_by.${selected.id}`,
          defaultMessage: `Sort by ${selected.id}`
        })}`}
        withArrow={false}
        withClose={true}
      >
        <Heading6 className="title">
          {intl.formatMessage({
            id: "projects.toggle.sort",
            defaultMessage: "Sort projects"
          })}
        </Heading6>
        <hr />
        {SORT_TYPES.map(type => (
          <RadioField
            key={`sort-${type.id}`}
            forId={`sort-${type.id}`}
            name={name}
            label={`${intl.formatMessage({
              id: `projects.toggle.sort_by.${type.id}`,
              defaultMessage: `Sort by ${type.id}`
            })} (${type[sortOrder]})`}
            value={sortType === type.id}
            onChange={() => {
              setSortType(type.id);
            }}
          />
        ))}
      </S.ProjectsSort>
      <S.ToggleButton
        data-tip
        data-for="tooltip-reverse-sort"
        onClick={() => {
          setSortOrder(sortOrder === "ascend" ? "descend" : "ascend");
          document.activeElement.blur();
        }}
      >
        <ReactTooltip
          effect="solid"
          event="mouseover focus"
          eventOff="mouseout blur"
          place="bottom"
          id="tooltip-reverse-sort"
        >
          {intl.formatMessage({ id: "projects.sort.reverse" })}
        </ReactTooltip>
        <IconSortDirection
          color={theme.textLight}
          up={sortOrder === "descend"}
        />
      </S.ToggleButton>
      <button
        onClick={() => {
          toggleLayout(true);
          document.activeElement.blur();
        }}
        aria-label={intl.formatMessage({
          id: "projects.grid_layout"
        })}
        className={`toggle-button${gridLayout ? " active" : ""}`}
      >
        <GridIcon color={theme.textLight} />
      </button>
      <button
        onClick={() => {
          toggleLayout(false);
          document.activeElement.blur();
        }}
        aria-label={intl.formatMessage({
          id: "projects.list_layout"
        })}
        className={`toggle-button${gridLayout ? "" : " active"}`}
      >
        <ListIcon color={theme.textLight} />
      </button>
    </ToggleWrapper>
  );
};

ToggleLayout.propTypes = {
  toggleLayout: PropTypes.func,
  gridLayout: PropTypes.bool,
  sortType: PropTypes.string,
  setSortType: PropTypes.func,
  sortOrder: PropTypes.string,
  setSortOrder: PropTypes.func
};

export default ToggleLayout;
