import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

import InfoDialog from "Components/InfoDialog";

export const FormWrapper = styled.form`
  padding: 32px;
  min-height: 200px;
  width: 100%;
  .primary {
    margin-right: 10px;
  }
  textarea {
    width: 100%;
    box-sizing: border-box;
  }
  .toggle-field {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .toggle-field + .toggle-field {
    border-top: 1px solid #c9d0e4;
    padding-top: 24px;
  }
  .toggle-field + .actions {
    padding-top: 16px;
  }
  .row {
    > div {
      width: 100%;
      box-sizing: border-box;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  &.add-form {
    background: ${props => themeHelpers(props.theme, "sectionColor")};
    border-radius: 0 0 4px 4px;
    margin-bottom: 16px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  max-width: 246px;
  width: 100%;
  padding-right: 20px;
`;

export const InputDialog = styled(InfoDialog)`
  position: absolute;
  width: auto;
  top: 0;
  right: 20px;
  width: 24px;
  height: 24px;
`;
