import styled from "styled-components";

export const ActivityListLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-top: 0;

  .icon-only {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`;

export const ActivityFilterLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 32px;
  position: absolute;
  right: 0;
  top: -58px;
  &:first-letter {
    text-transform: uppercase;
    display: inline-block;
  }
`;

export const ActivityListScroll = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 -32px;
`;

export const ShowMoreWrapper = styled.div`
  text-align: center;
  padding-top: 14px;
  border-top: 1px solid ${props => props.theme.sectionBorder};
`;

const EmptyActivitiesLayout = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  background-color: ${props => props.theme.rowBackground};
  color: ${props => props.theme.sectionText};
  border-radius: 2px;
`;

export const AllEmpty = styled(EmptyActivitiesLayout)`
  font-size: 15px;
  padding: 68px 0 88px;
`;

export const CategoryEmpty = styled(EmptyActivitiesLayout)`
  padding: 26px 0;
  font-size: 13px;
`;

export const ImageWrapper = styled.div`
  margin: 0 auto 24px;
  width: 156px;
  svg {
    max-width: 100%;
    height: auto;
  }
`;
