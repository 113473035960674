import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import useLocalForage from "Hooks/useLocalForage";
import IconMinus from "Icons/MinusIcon";
import IconBug from "Icons/Bug";

import * as S from "./ReportPopup.styles";

const ReportPopup = ({ id, title, text, className }) => {
  const username = useSelector(({ app }) => app?.getIn(["me", "username"]));
  const [status, setStatus, error, isLoading] = useLocalForage(
    `report-popup-${id}`
  );

  if (isLoading || error) {
    return null;
  }

  return (
    <S.Layout className={className}>
      {status !== "minimized" ? (
        <S.Popup>
          <S.Minimize onClick={() => setStatus("minimized")}>
            <IconMinus />
          </S.Minimize>
          <S.Icon>
            <IconBug />
          </S.Icon>
          <S.Content>
            <S.Title>{title}</S.Title>
            <S.Text>{text}</S.Text>
            <Link to={`/-/users/${username}/tickets/open`}>
              <FormattedMessage id="reportabug" />
            </Link>
          </S.Content>
        </S.Popup>
      ) : (
        <S.Button
          className="secondary"
          to={`/-/users/${username}/tickets/open`}
        >
          <IconBug />
          <FormattedMessage id="reportabug" />
        </S.Button>
      )}
    </S.Layout>
  );
};

ReportPopup.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ReportPopup;
