import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Map, List } from "immutable";

import { ADMIN_ROLES } from "Constants/constants";
import { getEnvironmentURI } from "Libs/utils";

import SubNavBar from "Components/SubNavBar";

import * as S from "./styles";

const SubNavBarContainer = ({
  currentPathName,
  environmentId,
  organizationId,
  projectId
}) => {
  const [tabs, setTabs] = useState([]);
  const [apps, setApps] = useState({ installed: {}, routeEnabled: false });

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );
  const environment = useSelector(state =>
    state.environment?.getIn(["data", organizationId, projectId, environmentId])
  );
  const environmentUri = getEnvironmentURI(
    organizationId,
    projectId,
    environmentId
  );

  const me = useSelector(state => state.app?.getIn(["me"], new Map()));
  const getApps = () => {
    if (
      !me ||
      !(me.get("roles") || List())
        .toArray()
        .some(role => ADMIN_ROLES.includes(role))
    ) {
      return { routeEnabled: false, installed: {} };
    }

    let loadedApps = JSON.parse(localStorage.getItem("plugin-apps")) || {};

    if (!loadedApps?.installed) {
      return {
        routeEnabled: !!loadedApps?.routeEnabled,
        installed: {}
      };
    }

    return loadedApps;
  };

  useEffect(
    () => {
      getTabs();
    },
    [currentPathName, apps]
  );

  useEffect(
    () => {
      setApps(getApps());
    },
    [currentPathName]
  );

  const getTabs = () => {
    const pages = [
      {
        // Account pages
        regex: `^/-/users/${organizationId}/settings`,
        tabs: [
          {
            name: "settings",
            route: `/-/users/${organizationId}/settings`
          },
          {
            name: "connected_accounts",
            route: `/-/users/${organizationId}/settings/accounts`
          },
          {
            name: "security",
            route: `/-/users/${organizationId}/settings/security`
          },
          {
            name: "tokens",
            route: `/-/users/${organizationId}/settings/tokens`
          },
          {
            name: "ssh-keys",
            route: `/-/users/${organizationId}/settings/ssh-keys`
          }
        ]
      },
      {
        // Environment pages
        regex: `^/${organizationId}/${projectId}/${environmentId}`,
        tabs: [
          {
            name: "overview",
            route: environmentUri
          },
          {
            name: "metrics",
            route: `${environmentUri}/metrics`,
            permission: (currentPathName, links) => {
              return !!(
                links["#metrics"] && links["#metrics"][0].type === "dedicated"
              );
            }
          },
          {
            name: "services",
            route: `${environmentUri}/services`
          },
          {
            name: "backups",
            route: `${environmentUri}/backups`,
            permission: "#edit"
          },
          {
            name: "settings",
            route: `${environmentUri}/settings`,
            permission: "#edit"
          }
        ].concat(
          Object.keys(apps.installed)
            .filter(appId =>
              apps.installed[appId].hooks.some(
                hook =>
                  hook.route ===
                    "/:organizationId/:projectId/:environmentId/" &&
                  hook.type === "tab"
              )
            )
            .map(appId => {
              const appName = apps.installed[appId].name;
              const hook = apps.installed[appId].hooks.find(
                h =>
                  h.route === "/:organizationId/:projectId/:environmentId/" &&
                  h.type === "tab"
              );
              const title = hook.title || appName || appId;
              return {
                name: `plugin.${title}`,
                route: `${environmentUri}/-/app/${appId}`
              };
            })
        )
      },
      {
        // Project pages
        regex: `^/${organizationId}/${projectId}(/?)($|-/)(?!(integrations/setup))`,
        tabs: [
          {
            name: "overview",
            route: `/${organizationId}/${projectId}`
          },
          {
            name: "integrations",
            route: `/${organizationId}/${projectId}/-/integrations`,
            permission: "#edit"
          },
          {
            name: "settings",
            route: `/${organizationId}/${projectId}/-/settings`,
            permission: "#edit"
          }
        ].concat(
          Object.keys(apps.installed)
            .filter(appId =>
              apps.installed[appId].hooks.some(
                hook =>
                  hook.route === "/:organizationId/:projectId/" &&
                  hook.type === "tab"
              )
            )
            .map(appId => {
              const appName = apps.installed[appId].name;
              const hook = apps.installed[appId].hooks.find(
                h =>
                  h.route === "/:organizationId/:projectId/" && h.type === "tab"
              );
              const title = hook.title || appName || appId;
              return {
                name: `plugin.${title}`,
                route: `/${organizationId}/${projectId}/-/app/${appId}`
              };
            })
        )
      }
    ];
    setTabs(
      pages.find(route =>
        new RegExp(route.regex).test(decodeURIComponent(currentPathName))
      )?.tabs || []
    );
  };

  return (
    <S.Wrapper>
      <SubNavBar
        currentPathName={currentPathName}
        tabs={tabs}
        links={
          environmentId && environment?.data
            ? environment.data._links
            : project?.data._links
        }
      />
    </S.Wrapper>
  );
};

SubNavBarContainer.propTypes = {
  currentPathName: PropTypes.string.isRequired,
  environmentId: PropTypes.string,
  organizationId: PropTypes.string,
  projectId: PropTypes.string
};

export default SubNavBarContainer;
