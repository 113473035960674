import styled from "styled-components";

import ToggleWrapper from "Components/ToggleWrapper";
import Heading2 from "Components/styleguide/Heading2";

export const Wrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 12px 0;
  box-sizing: border-box;
  ${Heading2} {
    margin: 0;
  }
  ${ToggleWrapper} {
    box-shadow: none;
    span {
      padding: 4px;
    }
  }
  @media (max-width: 360px) {
    .search-wrapper {
      margin-top: 20px;
    }
  }
  @media (min-width: 480px) {
    .add-link {
      min-width: 102px;
    }
  }
  @media (min-width: 600px) {
    .filter_selector {
      margin: 0;
    }
  }
`;
