import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import LabeledInfo from "Components/LabeledInfo";

const AppInfos = ({ metadata, workers = 0 }) => {
  const intl = useIntl();

  return (
    <>
      <LabeledInfo
        label={intl.formatMessage({ id: "version" })}
        value={
          metadata.type.split(":")[1] || intl.formatMessage({ id: "unknown" })
        }
      />
      <LabeledInfo
        label={intl.formatMessage({ id: "size" })}
        value={metadata.size || intl.formatMessage({ id: "unknown" })}
      />
      <LabeledInfo
        label={intl.formatMessage({ id: "disk" })}
        value={metadata.disk || intl.formatMessage({ id: "unknown" })}
      />
      <LabeledInfo
        label={intl.formatMessage({ id: "workers" })}
        value={workers}
      />
      <LabeledInfo
        label={intl.formatMessage({ id: "crons" })}
        value={metadata.crons ? Object.keys(metadata.crons).length : 0}
      />
    </>
  );
};

AppInfos.propTypes = {
  metadata: PropTypes.object,
  workers: PropTypes.number
};

export default AppInfos;
