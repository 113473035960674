import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import {
  loadMoreProjectActivities,
  loadProjectActivities,
  loadEnvironmentActivities,
  loadMoreEnvironmentActivities,
  loadIntegrationActivities,
  loadMoreIntegrationActivities,
  getSelectors
} from "Reducers/activity";
import useDecodedParams from "Hooks/useDecodedParams";

import ActivityList from "../../components/ActivityList";
import Filter from "../../components/ActivityList/Filter";
import SectionHeader from "./SectionHeader";
import ActivitySectionSkeleton from "./ActivitySectionSkeleton";

// getOptions returns the options for the filter menu as well as the
// labels we use in the select menu, be careful when changing the value
// here since these values are passed to the action and so used to make
// the call to the API.
// Every time we filter we call the APi for activities, instead of
// filtering the ones we already have in the store
const getOptions = intl => [
  {
    value: "all_type",
    label: intl.formatMessage({ id: "all_type" })
  },
  {
    value: "backup",
    label: intl.formatMessage({ id: "backups" })
  },
  {
    value: "branch",
    label: intl.formatMessage({ id: "branch" })
  },
  {
    value: "certificate",
    label: intl.formatMessage({ id: "certificate" })
  },
  {
    value: "cron",
    label: intl.formatMessage({ id: "crons" })
  },
  {
    value: "git",
    label: intl.formatMessage({ id: "git" })
  },
  {
    value: "merge",
    label: intl.formatMessage({ id: "merge" })
  },
  {
    value: "sync",
    label: intl.formatMessage({ id: "sync" })
  },
  {
    value: "system",
    label: intl.formatMessage({ id: "system" })
  }
];

const filtersOnRecentTab = [
  "backup",
  "branch",
  "certificate",
  // "cron",
  "git",
  "merge",
  "sync",
  "system"
];

const ActivitySection = ({ context, intl }) => {
  const dispatch = useDispatch();
  const options = getOptions(intl);
  // temporary hack since backups from deleted enviroments give an error
  // so we remove backups from the filter options if this is loaded in the
  // context of a project
  if (context === "project") {
    options.splice(1, 1);
  }

  const optionsValues = options.map(o => o.value);

  const [showing, setShowing] = useState("recent");
  const [filters, setFilters] = useState(filtersOnRecentTab);

  const {
    projectId,
    organizationId,
    environmentId,
    integrationId
  } = useDecodedParams();
  const {
    all,
    completed,
    inProgress,
    pending,
    hasMore,
    isLoading,
    isLoadingMore
  } = getSelectors(context, {
    projectId,
    organizationId,
    environmentId,
    integrationId
  });

  const allActivities = useSelector(all);
  const completedActivities = useSelector(completed);
  const inProgressActivities = useSelector(inProgress);
  const pendingActivities = useSelector(pending);

  const project = useSelector(state =>
    state.project.getIn(["data", organizationId, projectId])
  );
  const hasMoreActivities = useSelector(hasMore);
  const isLoadingActivities = useSelector(isLoading);
  const isLoadingMoreActivities = useSelector(isLoadingMore);

  // We show just one section if the all tab is selected but also
  // if activities are empty
  const shouldShowFilter = showing === "all";

  const shouldShowRecentSection = showing === "recent";

  const shouldShowMoreButton =
    showing !== "recent" &&
    !(isLoadingActivities || isLoadingMoreActivities) &&
    hasMoreActivities;

  // If all is not already selected select everything, else select
  // every checkbox
  const handleAllPresed = () => {
    if (filters.indexOf("all_type") === -1) {
      setFilters(optionsValues);
    } else {
      setFilters([]);
    }
  };

  const handleFilterChange = e => {
    const { name } = e.target;
    if (name === "all_type") {
      return handleAllPresed(e);
    }

    toggleFilter(name);
  };

  const toggleFilter = filterName => {
    filters.indexOf(filterName) === -1
      ? setFilters([...filters, filterName])
      : setFilters(
          filters.filter(el => el !== filterName && el !== "all_type")
        );
  };

  // loadActivity dispatches a different action depending if we are
  // in the environment or project context, this will make the action
  // fetch the activities from the corresponding endpont
  const loadActivity = () => {
    switch (true) {
      case context === "environment":
        dispatch(
          loadEnvironmentActivities(
            projectId,
            environmentId,
            organizationId,
            filters
          )
        );
        break;
      case context.startsWith("integration"):
        if (!integrationId) break;
        dispatch(
          loadIntegrationActivities(
            organizationId,
            projectId,
            integrationId,
            filters
          )
        );
        break;
      default:
        dispatch(loadProjectActivities(projectId, organizationId, filters));
    }
  };

  // loadMoreActivity dispatches a different action depending if we are
  // in the environment or project context, this will make the action
  // fetch the activities from the corresponding endpont
  const loadMoreActivity = () => {
    switch (true) {
      case context === "environment":
        dispatch(
          loadMoreEnvironmentActivities(
            projectId,
            environmentId,
            organizationId,
            filters
          )
        );
        break;
      case context.startsWith("integration"):
        dispatch(
          loadMoreIntegrationActivities(
            organizationId,
            projectId,
            integrationId,
            filters
          )
        );
        break;
      default:
        dispatch(loadMoreProjectActivities(projectId, organizationId, filters));
    }
  };

  // handleTabChange handles switching between the all and recent tabs
  const handleTabChange = tabName => {
    setShowing(tabName);

    // We just want to reset the filters when switching tabs for now
    // but in the recent tab we want to hide the crons, so we pass all
    // of the filters except crons in that case
    setFilters(tabName === "recent" ? filtersOnRecentTab : []);
  };

  useEffect(loadActivity, [environmentId, projectId, integrationId, filters]);

  return (
    <Container aria-labelledby="project-activity-heading">
      <SectionHeader showing={showing} onTabChange={handleTabChange}>
        {shouldShowFilter && (
          <Filter
            options={options}
            activeOptions={filters}
            onChange={e => handleFilterChange(e)}
          />
        )}
      </SectionHeader>
      {shouldShowRecentSection ? (
        <>
          <SubsectionHeadingFirst>
            {intl.formatMessage({ id: "activities.headers.running" })}
            <ActivityCount> ({inProgressActivities.size})</ActivityCount>
          </SubsectionHeadingFirst>
          {isLoadingActivities ? (
            <ActivitySectionSkeleton />
          ) : (
            <BoxLayout>
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={inProgressActivities}
                activityType="running"
                activityIsLoading={isLoadingMoreActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext={context}
              />
            </BoxLayout>
          )}
          <SubsectionHeading>
            {intl.formatMessage({ id: "activities.headers.pending" })}
            {!(isLoadingActivities || isLoadingMoreActivities) && (
              <ActivityCount> ({pendingActivities.size})</ActivityCount>
            )}
          </SubsectionHeading>
          {isLoadingActivities ? (
            <>
              <ActivitySectionSkeleton />
              <ActivitySectionSkeleton />
            </>
          ) : (
            <BoxLayout>
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={pendingActivities}
                activityType="pending"
                activityIsLoading={isLoadingMoreActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext={context}
              />
            </BoxLayout>
          )}
          <SubsectionHeading>
            {intl.formatMessage({ id: "activities.headers.recent" })}
            {!(isLoadingActivities || isLoadingMoreActivities) && (
              <ActivityCount> ({completedActivities.size})</ActivityCount>
            )}
          </SubsectionHeading>
          {isLoadingActivities ? (
            <>
              <ActivitySectionSkeleton />
              <ActivitySectionSkeleton />
            </>
          ) : (
            <BoxLayout>
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={completedActivities}
                activityIsLoading={isLoadingMoreActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext={context}
              />
            </BoxLayout>
          )}
        </>
      ) : isLoadingActivities ? (
        <>
          <ActivitySectionSkeleton />
          <ActivitySectionSkeleton />
        </>
      ) : (
        <BoxLayout>
          <ActivityList
            organizationId={organizationId}
            projectId={projectId}
            loadMore={loadMoreActivity}
            loadActivitiesOfType={loadActivity}
            activities={allActivities}
            activityIsLoading={isLoadingMoreActivities}
            hasMore={hasMoreActivities}
            canEditProject={
              project &&
              typeof project.hasPermission === "function" &&
              project.hasPermission("#edit")
            }
            activityContext={context}
          />
        </BoxLayout>
      )}
      {shouldShowMoreButton && (
        <SectionFooter>
          <LinkButton onClick={loadMoreActivity}>Show more</LinkButton>
        </SectionFooter>
      )}
    </Container>
  );
};

ActivitySection.propTypes = {
  context: PropTypes.oneOf(["environment", "project"]),
  intl: PropTypes.object
};

const Container = styled.section`
  width: 100%;
  min-width: 65%;
  box-sizing: border-box;
  padding: 8px 0 !important;
`;

const BoxLayout = styled.div`
  padding: 0 32px;
`;

const SubsectionHeading = styled.h3`
  font-size: 15px;
  font-weight: 600;
  color: ${props => props.theme.sectionText};
`;

const SubsectionHeadingFirst = styled(SubsectionHeading)`
  margin-top: -10px;
`;

const ActivityCount = styled.span`
  font-size: 13px;
  color: ${props => props.theme.subtitleText};
`;

const SectionFooter = styled.footer`
  padding: 10px;
  text-align: center;
`;

const LinkButton = styled.button`
  background: transparent;
  cursor: pointer;
  padding: 6px 8px;
  height: auto;
  border: none;
  color: ${props => props.theme.links};
  font-size: 14px;

  &:hover,
  &:focus {
    background: transparent;
    text-decoration: underline;
  }
`;

export default injectIntl(ActivitySection);
