import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import ToggleLayout from "./ToggleLayout";
import Heading2 from "Components/styleguide/Heading2";

import * as S from "./styles";

const ProjectFilterBar = ({
  toggleLayout,
  gridLayout,
  sortType,
  setSortType,
  sortOrder,
  setSortOrder
}) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <Heading2>
        {intl.formatMessage({
          id: "projects.filter.all",
          defaultMessage: "All projects"
        })}
      </Heading2>
      <ToggleLayout
        toggleLayout={toggleLayout}
        gridLayout={gridLayout}
        sortType={sortType}
        setSortType={setSortType}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
    </S.Wrapper>
  );
};

ProjectFilterBar.propTypes = {
  toggleLayout: PropTypes.func,
  gridLayout: PropTypes.bool,
  organizationId: PropTypes.string,
  sortType: PropTypes.string,
  setSortType: PropTypes.func,
  sortOrder: PropTypes.string,
  setSortOrder: PropTypes.func
};

export default ProjectFilterBar;
