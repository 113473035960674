import React from "react";

import { Skeleton, Square } from "Components/Skeleton";

import * as S from "./styles";
import Graph from "./Graph";

const ServicesDisplaySkeleton = props => (
  <S.Container>
    <Skeleton {...props} synchronize>
      <S.Layout>
        <Square height="24px" width="140px" />
        <Graph />
      </S.Layout>
    </Skeleton>
  </S.Container>
);

export default ServicesDisplaySkeleton;
