import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import Button from "Components/Button";
import FullPageError from "Components/FullPageError";
const EnvironmentError = ({ organizationId, projectId, children }) => {
  const intl = useIntl();

  const environmentLoadingError = useSelector(({ environment }) => {
    const error = environment.get("environmentLoadingError");
    return error?.toJS ? error.toJS() : error;
  });

  const isLoading = useSelector(({ environment }) =>
    environment.get("isLoading")
  );

  if (!isLoading && environmentLoadingError) {
    let errorTitle = intl.formatMessage({ id: "error_loading_environment" });
    let errorMessage = intl.formatMessage({
      id: "error_loading_environment_description"
    });

    if (environmentLoadingError.code === 403) {
      errorTitle = intl.formatMessage({ id: "error_403_title" });
      errorMessage = intl.formatMessage({ id: "error_environment_403" });
    }

    return (
      <FullPageError title={errorTitle} message={errorMessage}>
        <div className="actions">
          <Button
            className="primary"
            as={Link}
            to={`/${organizationId}/${projectId}`}
            aria-label="Return to project"
          >
            Return to project
          </Button>
        </div>
      </FullPageError>
    );
  }

  return children;
};

EnvironmentError.propTypes = {
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  children: PropTypes.node
};

export default EnvironmentError;
