import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Ansi from "ansi-to-react";
import { List, Map } from "immutable";
import stripAnsi from "strip-ansi";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import VirtualList from "react-tiny-virtual-list";

import ModalWrapper from "Components/Modal";

const DotDotDot = styled.div`
  &:after {
    display: inline-block;
    content: "";
    animation: loader 2s infinite linear;
    width: 10px;
    margin-left: 2px;
    text-align: left;
  }

  @keyframes loader {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
  }
`;

const LogPre = styled.pre`
  code {
    background: ${props => props.theme.preBg};
  }

  > div {
    box-sizing: border-box;
    padding-left: 4px;
    padding-top: 16px;
  }
`;

const LogModal = ({
  log,
  intl,
  isOpen,
  closeModal,
  activity,
  loading,
  loadLogFromActivity
}) => {
  useEffect(
    () => {
      loadLogFromActivity();
    },
    [activity.id]
  );

  const jsLog = log;

  const copyText = jsLog.reduce((logText, jsLog) => {
    if (!jsLog || !jsLog.data) {
      return logText;
    }
    return `${logText} ${stripAnsi(jsLog.data.message)}`;
  }, "");

  return (
    <ModalWrapper
      isOpen={isOpen}
      closeModal={closeModal}
      modalClass="modal-build-log modal-large"
      title={intl.formatMessage({
        id: "build_log"
      })}
      copyText={copyText}
    >
      <LogPre id="log-scroll" style={{ height: "50vh" }}>
        {jsLog.size < 1 &&
          loading && (
            <DotDotDot>
              {intl.formatMessage({
                id: "loading"
              })}
            </DotDotDot>
          )}

        {!loading &&
          jsLog.length === 0 &&
          intl.formatMessage({ id: "log_empty" })}
        <VirtualList
          height={
            window.innerHeight *
            (window.innerHeight > 600 ? 50 / 100 : 30 / 100)
          }
          overscanCount={50}
          itemCount={loading ? jsLog.length + 1 : jsLog.length}
          itemSize={21}
          renderItem={({ style, index }) => (
            <div style={style} key={jsLog[index]?.data.timestamp}>
              {loading && index === jsLog.length ? (
                <DotDotDot>
                  {intl.formatMessage({
                    id: "loading"
                  })}
                </DotDotDot>
              ) : (
                <div>
                  <Ansi>{jsLog[index].data.message}</Ansi>
                </div>
              )}
            </div>
          )}
        />
      </LogPre>
    </ModalWrapper>
  );
};

LogModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  activity: PropTypes.object,
  loadLogFromActivity: PropTypes.func,
  log: PropTypes.array,
  isModalOpen: PropTypes.bool,
  intl: PropTypes.object,
  loading: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  const log = state.log || new Map();

  return {
    log: log.getIn(["data", props.activity.id, "log"], new List()),
    loading: log.getIn(["data", props.activity.id, "streamInProgress"], false)
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadLogFromActivity: () =>
    import("Reducers/log").then(reducer =>
      dispatch(reducer.loadLogFromActivity(props.activity))
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LogModal));
