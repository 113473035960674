import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ImmutablePropTypes from "react-immutable-proptypes";

import Loading from "Components/Loading";
import LogModal from "./LogModal";
import { EmptyActivities } from "Components/illustrations/";

import Activity from "../activities";
import * as S from "./ActivityList.styles";

const sortByCreationDate = (item1, item2) =>
  new Date(item2.created_at).getTime() - new Date(item1.created_at).getTime();

const ActivityEmpty = ({ activityType }) => {
  if (!activityType || activityType === "all") {
    return (
      <S.AllEmpty>
        <S.ImageWrapper>
          <EmptyActivities id="empty-activities" />
        </S.ImageWrapper>
        <FormattedMessage
          id="activities.empty.all"
          defaultMessage="You have no recent activity"
        />
      </S.AllEmpty>
    );
  }

  const messageId = `activities.empty.${activityType}`;
  const message = `You have no ${activityType} activity`;

  return (
    <S.CategoryEmpty className="empty-text">
      <FormattedMessage id={messageId} defaultMessage={message} />
    </S.CategoryEmpty>
  );
};

ActivityEmpty.propTypes = {
  activityType: PropTypes.string
};

const ActivityList = ({
  activities,
  activityContext,
  activityType,
  activityIsLoading,
  canEditProject,
  organizationId
}) => {
  const [showModal, toggleModal] = useState({ open: false, activity: null });

  const activityList = activities.valueSeq().sort(sortByCreationDate);
  const shouldShowEmptySection = activityList?.size === 0 && !activityIsLoading;

  return (
    <S.ActivityListLayout>
      <S.ActivityListScroll>
        {shouldShowEmptySection ? (
          <ActivityEmpty activityType={activityType} />
        ) : (
          <div>
            {activityList.map(activity => {
              return (
                <Activity
                  key={activity.id}
                  openModal={() => toggleModal({ open: true, activity })}
                  activity={activity}
                  canEditProject={canEditProject}
                  activityContext={activityContext}
                  organizationId={organizationId}
                  hideActionLink={true}
                />
              );
            })}
          </div>
        )}
        {activityIsLoading && <Loading iconOnly={true} />}
      </S.ActivityListScroll>

      {showModal.open && (
        <LogModal
          isOpen={showModal.open}
          closeModal={() => toggleModal({ open: false })}
          activity={showModal.activity}
        />
      )}
    </S.ActivityListLayout>
  );
};

ActivityList.propTypes = {
  activities: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    ImmutablePropTypes.list
  ]),
  activityType: PropTypes.string,
  canEditProject: PropTypes.bool,
  loadActivitiesOfType: PropTypes.func,
  activityContext: PropTypes.string,
  organizationId: PropTypes.string,
  activityIsLoading: PropTypes.bool,
  hideActionLink: PropTypes.bool
};

export default ActivityList;
