import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import useUniqueId from "Libs/useUniqueId";

const WarningIconLayout = styled.span`
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  svg {
    width: 100%;
  }
`;

const WarningIcon = ({ color = "#5f5e70" }) => {
  const uid = useUniqueId();

  return (
    <WarningIconLayout className="icon-warning-wrapper">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{ enableBackground: "new 0 0 24 24" }}
        xmlSpace="preserve"
        role="img"
        aria-labelledby={`warning-icon-${uid}`}
      >
        <title id={`warning-icon-${uid}`}>Warning icon</title>
        <path
          className="icon-warning"
          d="M20.8,18.2L13.3,4.3c-0.6-1-2-1-2.6,0L3.2,18.2c-0.6,1,0.2,2.2,1.3,2.2h15C20.6,20.5,21.3,19.2,20.8,18.2z
          M12,17.5c-0.6,0-1-0.4-1-1s0.4-1,1-1c0.6,0,1,0.4,1,1S12.6,17.5,12,17.5z M13,13.5c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1v-4
          c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1V13.5z"
          fill={color}
        />
      </svg>
    </WarningIconLayout>
  );
};

WarningIcon.propTypes = {
  color: PropTypes.string
};

export default WarningIcon;
