import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import useInput from "Hooks/useInput";
import { capitalize, isDefined } from "Libs/utils";

import { DOCS_VARIABLES_URL } from "Constants/documentationUrls";
import Button, { RightAlignedButton } from "UI/Button";
import ButtonWrapper from "Components/ButtonWrapper";
import Error from "Components/Error";
import InputField from "Components/fields/InputField";
import Loading from "Components/Loading";
import TextAreaField from "Components/fields/TextAreaField";
import ToggleSwitch from "Components/ToggleSwitch";

import * as S from "./styles";

const VariableForm = ({
  environmentForm,
  errors = {},
  inherited,
  isChanged,
  isChangedUpdate,
  isLoading,
  onCancel,
  onDelete,
  onSave,
  variable
}) => {
  const intl = useIntl();

  const { inputs, handleInputChange } = useInput(
    {
      is_inheritable: isDefined(variable?.is_inheritable)
        ? variable?.is_inheritable
        : true,
      is_json: isDefined(variable?.is_json) ? variable?.is_json : false,
      is_sensitive: isDefined(variable?.is_sensitive)
        ? variable?.is_sensitive
        : false,
      is_enabled: isDefined(variable?.is_enabled) ? variable?.is_enabled : true,
      name: variable?.name || "",
      value: variable?.value || "",
      visible_build: isDefined(variable?.visible_build)
        ? variable?.visible_build
        : true,
      visible_runtime: isDefined(variable?.visible_runtime)
        ? variable?.visible_runtime
        : true
    },
    isChangedUpdate
  );
  const [overridden, setOverridden] = useState(false);
  const [valueFocused, setValueFocused] = useState(false);

  const isNew = !variable?.id;

  const showSaveAndCancelButtons = isChanged || overridden || isNew;
  const showOverrideButton =
    variable?.is_inherited && inputs.is_enabled && !overridden;
  const showDeleteButton =
    variable?.hasPermission && variable?.hasPermission("#delete");

  const handleFocus = () => {
    setValueFocused(true);
  };

  const handleBlur = () => {
    setValueFocused(false);
  };

  const handleSave = e => {
    e?.preventDefault();

    if (isNew && (!inputs.name || !inputs.value)) return;

    // sensitive var doesn't display value
    if (!isNew && (!inputs.name || (!inputs.is_sensitive && !inputs.value)))
      return;

    let data = {
      id: variable?.id || null,
      name: inputs.name,
      value: inputs.value,
      is_json: inputs.is_json,
      is_sensitive: inputs.is_sensitive
    };
    if (environmentForm) {
      data = Object.assign(data, {
        inherited: variable?.inherited || false,
        is_enabled: inputs.is_enabled,
        is_inheritable: inputs.is_inheritable
      });
    } else {
      data = Object.assign(data, {
        visible_build: inputs.visible_build,
        visible_runtime: inputs.visible_runtime
      });
    }
    onSave(data);
  };

  return (
    <S.Wrapper>
      <LiveMessage
        message={
          isNew
            ? intl.formatMessage({ id: "edit_variable" })
            : intl.formatMessage({ id: "add_variable" })
        }
        aria-live="polite"
      />

      <S.FormWrapper aria-labelledby="edit-variable-heading">
        {errors[""] && <Error>{errors[""]}</Error>}

        <div className="row">
          <S.InputWrapper>
            <InputField
              id="name"
              label={intl.formatMessage({ id: "name" })}
              value={inputs.name}
              onChange={elt => handleInputChange(elt)}
              error={errors["name"]}
              isDisabled={!isNew || (inherited && !overridden)}
            />
            {!isNew && (
              <S.InputDialog
                text={intl.formatMessage({
                  id: "settings.variables.edit_name",
                  defaultMessage:
                    "Variable names can not be edited once created. To adjust one, you’ll need to create another and delete the one you’re replacing"
                })}
                linkText={intl.formatMessage({
                  id: "learnmore",
                  defaultMessage: "Learn more"
                })}
                to={DOCS_VARIABLES_URL}
              />
            )}
          </S.InputWrapper>

          <TextAreaField
            id="value"
            label={intl.formatMessage({ id: "value" })}
            value={
              variable?.is_sensitive && !valueFocused && !inputs.value
                ? "*****"
                : inputs.value
            }
            onChange={elt => handleInputChange(elt)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={errors["value"]}
            isDisabled={inherited && !overridden}
          />
        </div>

        <ToggleSwitch
          id="is_json"
          title={intl.formatMessage({ id: "is_json" })}
          description={intl.formatMessage({
            id: "is_json.description"
          })}
          value={inputs.is_json}
          onClick={elt => handleInputChange(elt)}
          enabled={!inherited || !(inherited && !overridden)}
        />

        <ToggleSwitch
          id="is_sensitive"
          title={intl.formatMessage({ id: "sensitive" })}
          description={intl.formatMessage({
            id: "sensitive.description"
          })}
          value={inputs.is_sensitive}
          onClick={elt => handleInputChange(elt)}
          enabled={
            (!inherited || !(inherited && !overridden)) &&
            !variable?.is_sensitive
          }
        />

        {environmentForm ? (
          <>
            <ToggleSwitch
              id="is_enabled"
              title={intl.formatMessage({ id: "enabled" })}
              description={intl.formatMessage({
                id: "enabled.description"
              })}
              value={inputs.is_enabled}
              onClick={elt => handleInputChange(elt)}
              enabled={!inherited || !(inherited && !overridden)}
            />

            <ToggleSwitch
              id="is_inheritable"
              title={intl.formatMessage({ id: "inheritable" })}
              description={intl.formatMessage({
                id: "inheritable.description"
              })}
              value={inputs.is_inheritable}
              onClick={elt => handleInputChange(elt)}
              enabled={!inherited || !(inherited && !overridden)}
            />
          </>
        ) : (
          <>
            <ToggleSwitch
              id="visible_build"
              title={intl.formatMessage({ id: "during_build" })}
              description={intl.formatMessage({
                id: "during_build.description"
              })}
              value={inputs.visible_build}
              onClick={elt => handleInputChange(elt)}
            />
            <ToggleSwitch
              id="visible_runtime"
              title={intl.formatMessage({ id: "during_run" })}
              description={intl.formatMessage({
                id: "during_run.description"
              })}
              value={inputs.visible_runtime}
              onClick={elt => handleInputChange(elt)}
            />
          </>
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <ButtonWrapper className="actions">
            <div>
              {showSaveAndCancelButtons && (
                <>
                  <Button
                    id="save-variable-btn"
                    type="submit"
                    aria-label={intl.formatMessage({
                      id: isNew ? "add_variable" : "save"
                    })}
                    onClick={handleSave}
                  >
                    {capitalize(
                      intl.formatMessage({
                        id: isNew ? "add_variable" : "save"
                      })
                    )}
                  </Button>
                  <Button
                    id="cancel-variable-btn"
                    type="button"
                    variant="secondary"
                    aria-label={intl.formatMessage({ id: "cancel" })}
                    onClick={onCancel}
                  >
                    {capitalize(intl.formatMessage({ id: "cancel" }))}
                  </Button>
                </>
              )}
              {showOverrideButton && (
                <Button
                  id={`variable-list-${variable.name}-override-btn`}
                  type="button"
                  arial-label="Override"
                  onClick={() => setOverridden(true)}
                >
                  {capitalize(intl.formatMessage({ id: "override" }))}
                </Button>
              )}
            </div>

            {showDeleteButton && (
              <RightAlignedButton
                variant="tertiary"
                id={`variable-list-${variable.name}-delete-btn`}
                type="button"
                aria-label={intl.formatMessage({ id: "delete" })}
                onClick={onDelete}
              >
                {capitalize(intl.formatMessage({ id: "delete" }))}
              </RightAlignedButton>
            )}
          </ButtonWrapper>
        )}
      </S.FormWrapper>
    </S.Wrapper>
  );
};

VariableForm.propTypes = {
  environmentForm: PropTypes.bool,
  errors: PropTypes.object,
  isChanged: PropTypes.bool,
  isChangedUpdate: PropTypes.func,
  inherited: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  variable: PropTypes.object
};

export default VariableForm;
